import Vue from "vue";
import VClamp from "vue-clamp";

export default Vue.extend({
  name: "Offerts",
  components: {
    VClamp
  },
  props: {
    kitchens: {
      type: [Object, Array],
      default: () => {
      },
      required: true
    }
  },
  data: () => ({
    selectedKitchen: -1
  }),
  methods: {
    selectKitchen(id) {
      console.log(id);
      (this.selectedKitchen === id) ? this.selectedKitchen = -1 : this.selectedKitchen = id;
    },
    goToRestaurant(item) {
      this.$router.push({
        path: `/restaurant/${item.headquater.belongsTo}/${item.headquater.id}`,
        query: {
          promotions: ""
        }
      });
    }
  }
});
